import { circle } from '@turf/circle';

/**
 * Display range rings around the specified point in the map.
 * @see
 * - https://github.com/cloudninewx/OpenSnow-Android-Neue/commit/e275c096583560fbe223ecd17cb6c10967502e6c
 * - https://github.com/cloudninewx/Projects/issues/874
 */
const RangeRingHelper = {
  SOURCE_ID_RINGS: 'range_rings_rings_source',
  SOURCE_ID_LABEL: 'range_rings_labels_source',
  SOURCE_ID_ADDITIONAL_LABELS: 'range_rings_additional_labels_source',
  LAYER_ID_RINGS: 'range_rings_rings_layer',
  LAYER_ID_RINGS_OUTLINE: 'range_rings_rings_outline_layer',
  LAYER_ID_LABEL: 'range_rings_labels_layer',
  LAYER_ID_ADDITIONAL_LABELS: 'range_rings_additional_labels_layer',

  /**
   * The zoom threshold above which close rings are displayed. i.e. only display close rings when
   * zoomed in above this zoom threshold.
   */
  closeRingZoomThreshold: 9.0,

  /**
   * The distance threshold which defines close and far rings. Close rings are less than this
   * distance from the point. Far rings are further than or equal to this distance from the point.
   */
  closeRingDistanceThreshold: {
    metric: 16,
    imperial: 10.0,
  },

  /**
   * Default ring radii
   */
  defaultRingSpacing: {
    metric: [8, 12, 16, 32, 48],
    imperial: [5.0, 7.5, 10.0, 20.0, 30.0],
  },

  getRingColor(distance, units) {
    return distance <= this.defaultRingSpacing[units][2]
      ? '#df2b1d' // var(--saturated-red)
      : '#ffffff';
  },

  /**
   * Return true if the ring is close to the point
   */
  isClose(distance, units = 'imperial') {
    return distance < this.closeRingDistanceThreshold[units];
  },

  /**
   * Return the number of steps/points used to draw the ring given its radius
   */
  ringSteps(distance) {
    return Math.round(distance * 15);
  },
};

/**
 * Build a polygon feature set of points in a circle with the specified radius from the given
 * point (lngLat). And then build a point feature set for the point with the maximum latitude,
 * i.e. the northernmost point in the circle. The polygon feature set will be used for the circle
 * while the point feature set will be used for the distance labels which will be placed at the
 * top of the ring.
 * @see
 * - https://www.npmjs.com/package/@turf/circle/v/6.5.0
 * - https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js
 */
const buildFeatures = (lngLat, radius, units = 'imperial') => {
  const center = [lngLat.lng, lngLat.lat];
  const options = {
    properties: {
      distance: radius,
      isClose: RangeRingHelper.isClose(radius, units),
      ringColor: RangeRingHelper.getRingColor(radius, units),
    },
    steps: RangeRingHelper.ringSteps(radius),
    units: units === 'imperial' ? 'miles' : 'kilometers',
  };

  const ringFeature = circle(center, radius, options);

  const maxLatCoordinate = ringFeature.geometry.coordinates[0].reduce((acc, value) => {
    if (!acc) {
      acc = value;
    }
    else if (value[1] > acc[1]) {
      acc = value;
    }

    return acc;
  }, null);

  const labelFeature = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: maxLatCoordinate,
    },
    properties: {
      distance: radius,
      isClose: RangeRingHelper.isClose(radius, units),
      label: `${radius} ${units === 'imperial' ? 'mi' : 'km'}`,
    },
  };

  const additionalCoordinates = ringFeature.geometry.coordinates[0].reduce((acc, coordinate) => {
    if (!acc) {
      acc = {
        east: coordinate,
        south: coordinate,
        west: coordinate,
      };

      return acc;
    }

    const [lng, lat] = coordinate;

    if (lng > acc.east[0]) {
      acc.east = coordinate;
    }

    if (lat < acc.south[1]) {
      acc.south = coordinate;
    }

    if (lng < acc.west[0]) {
      acc.west = coordinate;
    }

    return acc;
  }, null);

  const getAdditionalLabelsFeatureProperties = () => ({
    distance: radius,
    isClose: RangeRingHelper.isClose(radius, units),
    label: `${radius} ${units === 'imperial' ? 'mi' : 'km'}`,
  });

  const additionalLabelsFeatures = [
    {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: additionalCoordinates.east },
      properties: getAdditionalLabelsFeatureProperties(),
    },
    {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: additionalCoordinates.south },
      properties: getAdditionalLabelsFeatureProperties(),
    },
    {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: additionalCoordinates.west },
      properties: getAdditionalLabelsFeatureProperties(),
    },
  ];

  return {
    additionalLabelsFeatures,
    ringFeature,
    labelFeature,
  };
};

const addRingLayer = (map, featureCollection, layerBelow) => {
  map.addSource(RangeRingHelper.SOURCE_ID_RINGS, {
    data: featureCollection,
    type: 'geojson',
  });

  const lineOpacityProps = {
    // Show close rings when zoomed in above the close ring zoom threshold
    'line-opacity': ['step',
      ['zoom'],
      ['case', ['get', 'isClose'], 0.0, 1],
      RangeRingHelper.closeRingZoomThreshold, 1,
    ],
    'line-opacity-transition': { delay: 0, duration: 500 },
  };

  map.addLayer({
    id: RangeRingHelper.LAYER_ID_RINGS_OUTLINE,
    paint: {
      'line-blur': ['step', ['zoom'], 0.75, 6, 1, 8, 1.5, 10, 1.75],
      'line-color': '#000000',
      'line-width': ['step', ['zoom'], 3, 6, 4, 8, 6, 10, 7],
      ...lineOpacityProps,
    },
    source: RangeRingHelper.SOURCE_ID_RINGS,
    type: 'line',
  }, layerBelow);

  map.addLayer({
    id: RangeRingHelper.LAYER_ID_RINGS,
    paint: {
      'line-color': ['get', 'ringColor'],
      'line-width': ['step', ['zoom'], 1, 6, 2, 8, 3, 10, 3.5],
      ...lineOpacityProps,

    },
    source: RangeRingHelper.SOURCE_ID_RINGS,
    type: 'line',
  }, layerBelow);
};

const labelLayerLayout = {
  'text-anchor': 'center',
  'text-field': ['get', 'label'],
  // TODO: Pass mapStyle to RangeRings when rendered in an Overlay.
  'text-font': ['Open Sans Bold', 'Open Sans Regular', 'Arial Unicode MS Regular'],
  'text-size': ['interpolate', ['linear'], ['zoom'], 12, 13, 20, 10],
};

const labelLayerPaint = {
  'text-color': '#ffffff',
  'text-halo-color': '#000000',
  'text-halo-width': 1,
  'text-opacity-transition': { delay: 0, duration: 500 },
};

const addLabelLayer = (map, featureCollection, layerBelow) => {
  map.addSource(RangeRingHelper.SOURCE_ID_LABEL, {
    data: featureCollection,
    type: 'geojson',
  });

  map.addLayer({
    id: RangeRingHelper.LAYER_ID_LABEL,
    layout: {
      ...labelLayerLayout,
    },
    paint: {
      ...labelLayerPaint,
      'text-opacity': ['step',
        ['zoom'],
        ['case', ['get', 'isClose'], 0.0, 1],
        RangeRingHelper.closeRingZoomThreshold, 1,
      ],
    },
    source: RangeRingHelper.SOURCE_ID_LABEL,
    type: 'symbol',
  }, layerBelow);
};

const addAdditionalLabelsLayer = (map, featureCollection, layerBelow) => {
  map.addSource(RangeRingHelper.SOURCE_ID_ADDITIONAL_LABELS, {
    data: featureCollection,
    type: 'geojson',
  });

  map.addLayer({
    id: RangeRingHelper.LAYER_ID_ADDITIONAL_LABELS,
    layout: {
      ...labelLayerLayout,
    },
    paint: {
      ...labelLayerPaint,
      'text-opacity': ['step', ['zoom'], 0, 11, 1],
    },
    source: RangeRingHelper.SOURCE_ID_ADDITIONAL_LABELS,
    type: 'symbol',
  }, layerBelow);
};

export default {
  add(params = {}) {
    const {
      layerBelow,
      lngLat,
      map,
      units = 'imperial',
    } = params;

    // Remove any existing range rings
    this.remove(map);

    // Create feature collections
    const features = RangeRingHelper
      .defaultRingSpacing[units]
      .map((radii) => buildFeatures(lngLat, radii, units));

    const ringFeatures = features.map(({ ringFeature }) => ringFeature);
    const labelFeatures = features.map(({ labelFeature }) => labelFeature);
    const additionalLabelsFeatures = features.flatMap(({ additionalLabelsFeatures }) => additionalLabelsFeatures);

    const ringFeatureCollection = {
      type: 'FeatureCollection',
      features: ringFeatures,
    };

    const labelFeatureCollection = {
      type: 'FeatureCollection',
      features: labelFeatures,
    };

    const addAdditionalLabelsFeatureCollection = {
      type: 'FeatureCollection',
      features: additionalLabelsFeatures,
    };

    addRingLayer(map, ringFeatureCollection, layerBelow);
    addLabelLayer(map, labelFeatureCollection, layerBelow);
    addAdditionalLabelsLayer(map, addAdditionalLabelsFeatureCollection, layerBelow);
  },

  remove(map) {
    if (map.getLayer(RangeRingHelper.LAYER_ID_ADDITIONAL_LABELS)) {
      map.removeLayer(RangeRingHelper.LAYER_ID_ADDITIONAL_LABELS);
    }

    if (map.getLayer(RangeRingHelper.LAYER_ID_LABEL)) {
      map.removeLayer(RangeRingHelper.LAYER_ID_LABEL);
    }

    if (map.getLayer(RangeRingHelper.LAYER_ID_RINGS_OUTLINE)) {
      map.removeLayer(RangeRingHelper.LAYER_ID_RINGS_OUTLINE);
    }

    if (map.getLayer(RangeRingHelper.LAYER_ID_RINGS)) {
      map.removeLayer(RangeRingHelper.LAYER_ID_RINGS);
    }

    if (map.getSource(RangeRingHelper.SOURCE_ID_ADDITIONAL_LABELS)) {
      map.removeSource(RangeRingHelper.SOURCE_ID_ADDITIONAL_LABELS);
    }

    if (map.getSource(RangeRingHelper.SOURCE_ID_LABEL)) {
      map.removeSource(RangeRingHelper.SOURCE_ID_LABEL);
    }

    if (map.getSource(RangeRingHelper.SOURCE_ID_RINGS)) {
      map.removeSource(RangeRingHelper.SOURCE_ID_RINGS);
    }
  },
};
