<script>
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import SnowTextLabelMixin from './Mixins/SnowTextLabelMixin';
import StaticOverlayMixin from './Mixins/StaticOverlayMixin';

export default {
  name: 'SnowDepthOverlay',

  mixins: [
    OpacityMixin,
    OverlayMixin,
    SnowTextLabelMixin,
    StaticOverlayMixin,
  ],

  data() {
    return {
      isAllAccess: true,
      layers: [],
      mapSourceId: null,
      source: {
        get_forecasts: false,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.snowdepth,
        tile_count: 1,
        tile_sort: 'asc',
        tile_types: ['contours'],
      },
    };
  },
};
</script>
