<script>
import { mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import { addSource, colorInterpolate } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import MarkerMixin from './Mixins/MarkerMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import RangeRings from './RangeRings';

export default {
  name: 'LightningNowcastOverlay',

  mixins: [
    AnimatedOverlayMixin,
    MarkerMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  setup() {
    const { hasGeoLocationPermissionBeenGranted, getUserLocation } = useUserLocation();
    return { hasGeoLocationPermissionBeenGranted, getUserLocation };
  },

  data() {
    return {
      frames: [],
      isAllAccess: true,
      popup: {
        className: 'location-hover-popup',
        enabled: true,
        control: null,
        layerId: null,
        featureId: null,
        mouseEvent: null,
        timeoutId: null,
      },
      source: {
        get_forecasts: false,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.lightningRisk,
        tile_count: 24,
        tile_sort: 'asc',
        tile_types: ['points'],
      },
      userLocation: null,
    };
  },

  computed: {
    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),
  },

  watch: {
    userLocation() {
      if (this.isActive) {
        this.removeMarker();
        this.showRangeRings();
      }
    },
  },

  methods: {
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();
      await this.createPopup();

      if (this.frames.length > 0) {
        this.show();
        this.addRangeRingEvents();
        this.showRangeRings();
        return;
      }

      this.addOverlayMask();

      this.tiles.forEach((tile) => this.addFrame(tile));

      this.addEvents();
      this.show();

      // this is needed to fire the fancy opacity stuff in OpacityMixin
      this.handleOpacityChange();

      this.addRangeRingEvents();
      this.showRangeRings();
    },

    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const source = addSource({ tile }, this);

      if (!source) {
        return;
      }

      const strikes = this.addStrikes(tile);
      const contours = this.addContours(tile);
      const eventLayers = [
        contours.id,
        strikes.id,
      ];

      let eventsOn = false;
      this.frames.push({
        layers: [strikes, contours],
        source,
        source_timestamp,
        addEvents: () => {
          if (eventsOn) {
            return;
          }

          if (this.$device.isMobileOrTablet) {
            this.map.on('click', this.hidePopupImmediate);
            this.map.on('movestart', this.hidePopupImmediate);
          }

          if (this.$device.isMobileOrTablet) {
            this.map.on('click', eventLayers, this.showPopup);
          }
          else {
            this.map.on('mousemove', eventLayers, this.showPopup);
            this.map.on('mouseleave', eventLayers, this.hidePopup);

            // keep popup visible while animating but update its content
            // to new feature under pointer
            if (this.popup.mouseEvent) {
              this.map.getCanvas().dispatchEvent(
                new MouseEvent(this.popup.mouseEvent.type, this.popup.mouseEvent),
              );
            }
          }

          eventsOn = true;
        },

        removeEvents: () => {
          this.hidePopup();

          if (this.$device.isMobileOrTablet) {
            this.map.off('click', this.hidePopupImmediate);
            this.map.off('movestart', this.hidePopupImmediate);
          }

          if (this.$device.isMobileOrTablet) {
            this.map.off('click', eventLayers, this.showPopup);
          }
          else {
            this.map.off('mousemove', eventLayers, this.showPopup);
            this.map.off('mouseleave', eventLayers, this.hidePopup);
          }

          eventsOn = false;
        },
      });
    },

    addStrikes(tile) {
      const { name } = tile.index_json;
      const sourceLayer = 'points';
      const layerId = `${name}-strikes`;
      const strikesColor = '#DD4422';
      const newStrikesColor = '#F7F428';

      this.map.addLayer({
        'id': layerId,
        'type': 'circle',
        'source': name,
        'source-layer': sourceLayer,
        'layout': {
          visibility: 'none',
        },
        'paint': {
          'circle-opacity': 0,
          'circle-blur': 0,
          'circle-radius': [
            'interpolate',
            ['linear'], ['zoom'],
            0, 1,
            2, 2,
            8, 8,
          ],
          'circle-color': strikesColor,
          'circle-stroke-color': newStrikesColor,
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 0,
        },
      });

      const fillOpacityFunction = (opacity) => ['interpolate', ['linear'], ['get', 'flash_age'], 0, ['*', 1, opacity], 60, ['*', 0.2, opacity]];
      const strokeOpacityFunction = (opacity) => ['*', opacity, ['case', ['<=', ['get', 'flash_age'], 5], 1, 0]];

      return { id: layerId, opacities: ['circle-opacity', 'circle-stroke-opacity'], opacityValues: [fillOpacityFunction, strokeOpacityFunction] };
    },

    addContours(tile) {
      const { name } = tile.index_json;
      const sourceLayer = 'contours';
      const layerId = `${name}-contours`;
      const layerIdBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'road-primary';

      this.map.addLayer({
        'id': layerId,
        'type': 'fill',
        'source': name,
        'source-layer': sourceLayer,
        'layout': {
          'visibility': 'none',
          'fill-sort-key': ['get', 'DN'],
        },
        'paint': {
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'DN'],
            ...colorInterpolate(this.mapSource.legends[0].steps)],
          'fill-opacity': 0,
        },
      }, layerIdBelow);

      return { id: layerId, opacities: ['fill-opacity'] };
    },

    disablePopup() {
      this.popup.enabled = false;
      this.hidePopupImmediate();
    },

    enablePopup() {
      this.popup.enabled = true;
    },

    addEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.on('popup.show', this.disablePopup);
      this.map.on('popup.hide', this.enablePopup);
    },

    addRangeRingEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.on('click', this.showRangeRings);
    },

    removeEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.off('popup.show', this.disablePopup);
      this.map.off('popup.hide', this.enablePopup);
    },

    removeRangeRingEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.off('click', this.showRangeRings);
    },

    async createPopup() {
      this.popup.control = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        maxWidth: '300px',
        offset: 10,
        className: this.popup.className,
      });
    },

    clearPopupTimeout() {
      if (!this.popup.timeoutId) {
        return;
      }

      window.clearTimeout(this.popup.timeoutId);
      this.popup.timeoutId = null;
    },

    handleHide() {
      this.removeMarker();
      RangeRings.remove(this.map);
      this.removeRangeRingEvents();
      this.hidePopup();
    },

    handleRemove() {
      this.removeMarker();
      RangeRings.remove(this.map);
      this.removeRangeRingEvents();
      this.hidePopup();
    },

    htmlPopup(features) {
      const { strike, forecast } = features;
      let text;

      if (strike) {
        const frameTime = this.$dayjs.utc(this.frames[this.currentFrame].source_timestamp);
        const flashAge = strike.properties.flash_age * 60;
        const flashTime = frameTime.subtract(flashAge, 'seconds');
        text = `<div>Recent strike at ${flashTime.local().format('ddd, MMM D h:mm A z')}</div>`;
      }
      else if (forecast) {
        const value = Math.round(forecast.properties.DN);
        text = `<div class="tw-text-center"><b>${value}%</b> chance of lightning in next 60 minutes</div>`;
      }

      return `
        <div>
          ${text}
        </div>
      `;
    },

    showPopup(e) {
      if (!this.popup.enabled || this.map.getZoom() < 2) {
        return;
      }
      // don't do flash popups below zoom 6
      const [contourFeature] = e.features.filter((f) => f.layer['source-layer'] === 'contours');
      const [pointFeature] = e.features.filter((f) => f.layer['source-layer'] === 'points');
      const features = {
        strike: pointFeature,
        forecast: contourFeature,
      };

      this.popup.mouseEvent = e.originalEvent;

      const show = () => {
        this.clearPopupTimeout();
        this.popup.control.setHTML(this.htmlPopup(features));

        this.popup.control
          .setLngLat(e.lngLat)
          .addTo(this.map);

        this.popup.layerId = (contourFeature?.layer.id ?? pointFeature?.layer.id) ?? null;
      };

      if (!this.$device.isMobileOrTablet) {
        show();
        return;
      }

      window.setTimeout(() => {
        if (e.originalEvent.handledBy && e.originalEvent.handledBy !== this.$options.name) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it.
          // Do not ignore the event if it was handled by the Lightning Risk overlay. This allows
          // range rings to be initially displayed and then a popup to displayed when clicking on a
          // strike on mobile browsers.
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }

        e.originalEvent.handledBy = this.$options.name;
        show();

        const popup = this.map.getContainer().querySelector(`.${this.popup.className}`);
        const closeHandler = (clickEvent) => {
          this.hidePopupImmediate(clickEvent);
          popup.removeEventListener('click', closeHandler);
        };
        popup.addEventListener('click', closeHandler);
      }, 75);
    },

    hidePopup() {
      if (this.popup.timeoutId) {
        return;
      }

      this.popup.timeoutId = setTimeout(this.hidePopupImmediate, 250);
    },

    hidePopupImmediate() {
      if (this.popup?.control?.isOpen()) {
        this.popup.control.remove();
      }

      this.popup.featureId = null;
      this.popup.layerId = null;
      this.popup.mouseEvent = null;
      this.popup.timeoutId = null;
    },

    async showRangeRings(e) {
      if (e?.originalEvent) {
        e.originalEvent.stopImmediatePropagation();
        e.originalEvent.handledBy = this.$options.name;
      }

      const params = {
        layerBelow: this.map.getLayer('single-locations') ? 'single-locations' : undefined,
        lngLat: null,
        map: this.map,
        units: this.units,
      };

      if (e && e.lngLat) {
        this.addMarker(e.lngLat, this.map);
        params.lngLat = e.lngLat;
      }
      else if (this.userLocation) {
        params.lngLat = mapboxgl.LngLat.convert(this.userLocation);
      }
      else {
        const geoLocationPermissionBeenGranted = await this.hasGeoLocationPermissionBeenGranted();

        if (geoLocationPermissionBeenGranted) {
          try {
            this.userLocation = await this.getUserLocation();

            if (this.userLocation) {
              params.lngLat = mapboxgl.LngLat.convert(this.userLocation);
            }
          }
          catch (e) {
            // If unable to get user location then just don't show the range rings
          }
        }
      }

      if (params.lngLat) {
        RangeRings.add(params);
      }
    },
  },
};
</script>
