<script>
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'RadarJapanOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      source: {
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.radarJapan,
        tile_sort: 'asc',
        tile_types: ['contours'],
        tile_count: 48,
      },
    };
  },
};
</script>
