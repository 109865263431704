import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    textFieldLabel() {
      const imperialLabel = ['concat', ['get', 'DN'], '"'];
      const metricLabel = [
        'concat',
        [
          'max',
          1,
          ['*', ['round', ['/', ['*', 2.54, ['get', 'DN']], 5]], 5],
        ],
        ' cm',
      ];

      return ['imperial', 'uk', 'us'].includes(this.units) ? imperialLabel : metricLabel;
    },
  },
};
