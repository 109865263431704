<script>
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'FallColorsOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      isForecast: true,
      source: {
        get_forecasts: true,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.fallcolors,
        tile_count: 13,
        // tiles are weekly, we want to show at most six months in the past to six months in the future
        // typically it's only 3 months in both directions, adding some buffer
        ranges: [{ start: -26, stop: 26, step: 1 }],
        tile_sort: 'asc',
        tile_types: ['contours'],
      },
    };
  },

  methods: {
    findCurrentFrame() {
      if (!this.frames?.length) {
        return 0;
      }

      // Find the absolute differences between now and the timestamp of each frame and then return
      // the minimum difference which is the timestamp of the frame closest to today. This ensures
      // that the frame closest to today will be shown initially when the Fall Colors Overlay is
      // displayed.

      const now = Date.now();
      const diffs = this.frames.map((frame) => Math.abs(now - Date.parse(frame.source_timestamp)));
      const mostRecentFrame = diffs.indexOf(Math.min(...diffs));
      return mostRecentFrame;
    },
  },
};
</script>
