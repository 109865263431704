<script>
import { mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import { formatNumber } from '@@/utils/CommonUtils';
import { colorInterpolate } from './Utils';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import StaticOverlayMixin from './Mixins/StaticOverlayMixin';

export default {
  name: 'PublicLandsOverlay',

  mixins: [
    OpacityMixin,
    OverlayMixin,
    StaticOverlayMixin,
  ],

  props: {
    isMiniMap: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPoint: null,
      layers: [],
      isAllAccess: false,
      isForecast: false,
      popupLayer: null,
      popup: {
        className: 'location-hover-popup',
        enabled: true,
        control: null,
        layerId: null,
        featureId: null,
        mouseEvent: null,
        timeoutId: null,
      },
      source: {
        get_forecasts: false,
        has_legend: true,
        has_tile_index: true,
        short_name: OverlayNames.publicLands,
        tile_count: 1,
        tile_sort: 'asc',
        tile_types: ['points'],
      },
    };
  },

  computed: {
    ...mapState(useMapStore, {
      mapCard: (state) => state.ui.mapCard,
    }),

    layerBelow() {
      if (this.map?.getLayer('single-locations')) {
        return 'single-locations';
      }

      return this.map?.getLayer('settlement-label')
        ? 'settlement-label'
        : 'settlement-minor-label';
    },
  },

  methods: {

    async add() {
      await this.checkForUpdatedMapSource();
      const [tile] = this.tiles;

      await this.createPopup();
      this.addSource({ tile });
      const fillLayer = this.addFillLayer(tile, this.layerBelow);
      this.layers.push(fillLayer);
      this.layers.push(this.addLineLayer(tile, this.layerBelow));
      this.layers.push(this.addLabels(tile, this.layerBelow));
      this.addMasks();
      this.addEvents(fillLayer.id);
      this.show();
      // this is needed to fire the fancy opacity stuff in OpacityMixin
      this.handleOpacityChange();
    },

    addLabels(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-id`;

      this.map.addLayer({
        id,
        'type': 'symbol',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 9,
        'layout': {
          'symbol-sort-key': ['-', ['get', 'acres']],
          'text-field': ['get', 'unit_name'],
          'text-font': this.mapStyle.textFont,
          'text-padding': 7,
          'text-size': 11,
          'visibility': 'none',
        },
        'paint': {
          'text-color': this.mapStyle.textColor,
          'text-halo-color': this.mapStyle.textHaloColor,
          'text-halo-blur': this.mapStyle.textHaloBlur,
          'text-halo-width': this.mapStyle.textHaloWidth,
          'text-opacity': 0,
        },
      }, layerBelow);

      return {
        id, moveToTop: true, opacities: ['text-opacity'], opacityValues: [0.9],
      };
    },

    disablePopup() {
      this.popup.enabled = false;
      this.hidePopupImmediate();
    },

    enablePopup() {
      this.popup.enabled = true;
    },

    addEvents(layerId) {
      this.map.on('popup.show', this.disablePopup);
      this.map.on('popup.hide', this.enablePopup);

      if (this.$device.isMobileOrTablet) {
        this.map.on('click', this.hidePopupImmediate);
        this.map.on('movestart', this.hidePopupImmediate);
      }
      if (this.$device.isMobileOrTablet) {
        this.map.on('click', [layerId], this.showPopup);
      }
      else {
        this.map.on('mousemove', [layerId], this.showPopup);
        this.map.on('mouseleave', [layerId], this.hidePopup);
        // keep popup visible while animating but update its content
        // to new feature under pointer
        if (this.popup.mouseEvent) {
          this.map.getCanvas().dispatchEvent(
            new MouseEvent(this.popup.mouseEvent.type, this.popup.mouseEvent),
          );
        }
      }
      this.popupLayer = layerId;
    },

    removeEvents() {
      this.map.off('popup.show', this.disablePopup);
      this.map.off('popup.hide', this.enablePopup);

      if (this.$device.isMobileOrTablet) {
        this.map.off('click', this.hidePopupImmediate);
        this.map.off('movestart', this.hidePopupImmediate);
      }

      if (this.popupLayer) {
        this.hidePopup();

        if (this.$device.isMobileOrTablet) {
          this.map.off('click', [this.popupLayer], this.showPopup);
        }
        else {
          this.map.off('mousemove', [this.popupLayer], this.showPopup);
          this.map.off('mouseleave', [this.popupLayer], this.hidePopup);
        }
      }
      this.popupLayer = null;
    },

    async createPopup() {
      this.popup.control = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        maxWidth: '300px',
        offset: 10,
        className: this.popup.className,
      });
    },

    clearPopupTimeout() {
      if (!this.popup.timeoutId) {
        return;
      }

      window.clearTimeout(this.popup.timeoutId);
      this.popup.timeoutId = null;
    },

    htmlPopup(sourceLayer, feature) {
      const { properties: props } = feature;

      const stats = [];
      if (props.acres != null) {
        stats.push(`${props.acres} ${props.acres === 1 ? 'acre' : 'acres'}`);
      }

      // the logic for how to describe the manager is a little weird
      let manager = props.manager_longname ?? props.manager_shortname;
      if (props.manager_code === 3) {
        manager = props.local_manager;
      }

      return `
        <div style="min-width: 240px">
          <div class="tw-mb-1 tw-text-base tw-font-bold" style="border-bottom: 1px solid #ccc;">${props.unit_name}</div>
          <div>
            ${(props.manager_type != null) ? `<div>Category: ${props.manager_type}</div>` : ''}
            ${(props.acres != null) ? `<div>${formatNumber(props.acres)} ${props.acres === 1 ? 'acre' : 'acres'}` : ''}
            ${(manager != null) ? `<div>Manager: ${manager}</div>` : ''}
            ${(props.access != null) ? `<div>Access: ${props.access}</div>` : '<div>Access: Unknown</div>'}
          </div>
        </div>
      `;
    },

    showPopup(e) {
      const [feature] = e.features;
      const layerId = feature.layer.id;
      const sourceLayer = feature.layer['source-layer'];
      this.popup.mouseEvent = e.originalEvent;

      // this should cover features that are created both with and without GDAL/OGR
      // since GDAL/OGR puts the ID on the feature, not in the properties
      const featureId = feature.id ?? feature.properties.id;

      if (!this.popup.enabled
        || (this.popup.featureId === featureId && layerId === this.popup.layerId)
        || (this.map.getZoom() < 7 && sourceLayer === 'public-lands')
      ) {
        return;
      }

      const show = () => {
        this.clearPopupTimeout();
        this.popup.control.setHTML(this.htmlPopup(sourceLayer, feature));

        if (this.popup.featureId !== feature.properties.id) {
          this.popup.control
            .setLngLat(e.lngLat)
            .addTo(this.map);

          if (!this.$device.isMobileOrTablet) {
            this.popup.control.trackPointer();
          }
        }

        this.popup.featureId = featureId;
        this.popup.layerId = layerId;
      };

      if (!this.$device.isMobileOrTablet) {
        show();
        return;
      }

      window.setTimeout(() => {
        if (e.originalEvent.handledBy) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }
        e.originalEvent.handledBy = this.$options.name;
        show();

        const popup = this.map.getContainer().querySelector(`.${this.popup.className}`);
        const closeHandler = (clickEvent) => {
          this.hidePopupImmediate(clickEvent);
          popup.removeEventListener('click', closeHandler);
        };
        popup.addEventListener('click', closeHandler);
      }, 50);
    },

    hidePopup() {
      if (this.popup.timeoutId) {
        return;
      }

      this.popup.timeoutId = setTimeout(this.hidePopupImmediate, 250);
    },

    hidePopupImmediate() {
      if (this.popup.control.isOpen()) {
        this.popup.control.remove();
      }

      this.popup.featureId = null;
      this.popup.layerId = null;
      this.popup.mouseEvent = null;
      this.popup.timeoutId = null;
    },

    addFillLayer(tile, layerBelow) {
      const { name } = tile.index_json;

      this.map.addLayer({
        'id': name,
        'type': 'fill',
        'source': name,
        'source-layer': tile.source_layer_id,
        'layout': {
          'visibility': 'none',
          'fill-sort-key': ['get', 'manager_code'],
        },
        'paint': {
          'fill-antialias': true,
          'fill-outline-color': 'rgba(0,0,0,0)',
          'fill-opacity': 0,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'manager_code'],
            ...colorInterpolate(this.mapSource.legends[0].steps, this.currentBaseMap),
          ],
        },
      }, layerBelow);

      const fillOpacityFunction = (opacity) => ['interpolate', ['linear'], ['zoom'], 7, ['*', 0.4, opacity], 10, ['*', 0.2, opacity]];

      return { id: name, opacities: ['fill-opacity'], opacityValues: [fillOpacityFunction] };
    },

    addLineLayer(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-line`;

      this.map.addLayer({
        id,
        'type': 'line',
        'source': name,
        'source-layer': tile.source_layer_id,
        'layout': {
          'visibility': 'none',
          'line-sort-key': ['get', 'manager_code'],
        },
        'paint': {
          'line-opacity': 0,
          'line-color': [
            'interpolate',
            ['linear'],
            ['get', 'manager_code'],
            ...colorInterpolate(this.mapSource.legends[0].steps, this.currentBaseMap),
          ],
          'line-width': ['interpolate', ['linear'], ['zoom'], 7, 0.0, 10, 2, 12, 4],
        },
      }, layerBelow);

      const lineOpacityFunction = (opacity) => ['interpolate', ['linear'], ['zoom'], 7, ['*', 0.0, opacity], 10, ['*', 0.7, opacity]];

      return { id, opacities: ['line-opacity'], opacityValues: [lineOpacityFunction] };
    },
  },
};
</script>
