import { MercatorCoordinate } from 'mapbox-gl';
import * as twgl from 'twgl.js';
import shaders from './Shaders';

export default function VectorField(map, gl) {
  // bounds are [minx, miny, maxx, maxy]
  let bounds = [0, 0, 0, 0];
  let dataRes = [0, 0];
  const range = [110, 150];
  let programInfo;
  let textures;
  let screenProgramInfo;
  let updateProgramInfo;
  let particleTextures;
  let numParticles;
  let framebuffer;
  let particleIndices;
  let particleRes;
  let state = 'PAUSED';
  let mapBounds;
  let xScale = 1.0;

  let overlayOpacity = 1.0;
  let fadeOpacity;
  let speedFactor;
  let dropRate;
  let dropRateBump;
  let pointSize = 2.0 * (window.devicePixelRatio || 1);

  let quadBufferInfo;
  let particleBufferInfo;

  let animationId;

  const nParticles = 3000;

  function setBounds(bnds) {
    const nw = bnds.getNorthWest();
    const se = bnds.getSouthEast();
    const nwMercator = MercatorCoordinate.fromLngLat(nw);
    const seMercator = MercatorCoordinate.fromLngLat(se);
    // minx miny maxx maxy
    const xDiff = Math.abs(seMercator.x - nwMercator.x);
    const yDiff = Math.abs(nwMercator.y - seMercator.y);
    xScale = yDiff / xDiff;
    mapBounds = [nwMercator.x, seMercator.y, seMercator.x, nwMercator.y];
  }

  function setParticles(num) {
    particleRes = Math.ceil(Math.sqrt(num));
    numParticles = particleRes * particleRes;

    const particleState = new Uint8Array(numParticles * 4);

    for (let i = 0; i < particleState.length; i += 1) {
      particleState[i] = Math.floor(Math.random() * 256);
    }

    if (particleTextures) {
      Object.values(particleTextures).forEach((i) => {
        gl.deleteTexture(i);
      });
    }
    if (particleBufferInfo?.attribs?.a_index?.buffer) {
      gl.deleteBuffer(particleBufferInfo.attribs.a_index.buffer);
    }

    particleTextures = twgl.createTextures(gl, {
      particleTexture0: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: particleRes,
        height: particleRes,
        format: gl.RGBA,
        src: particleState,
        wrap: gl.CLAMP_TO_EDGE,
      },
      particleTexture1: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: particleRes,
        height: particleRes,
        format: gl.RGBA,
        src: particleState,
        wrap: gl.CLAMP_TO_EDGE,
      },
    });

    particleIndices = new Float32Array(numParticles);
    for (let i = 0; i < numParticles; i += 1) {
      particleIndices[i] = i;
    }

    const arrays = {
      a_index: {
        numComponents: 1,
        data: particleIndices,
      },
    };

    particleBufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
  }

  function clear() {
    gl.clearColor(0.0, 0.0, 0.0, 0.0);

    // clear framebuffer textures
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, textures.screenTexture, 0,
    );
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, textures.backgroundTexture, 0,
    );
    gl.clear(gl.COLOR_BUFFER_BIT);

    // generate new random particle positions
    setParticles(nParticles);

    // target normal canvas
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    // clear canvas
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  function resize() {
    pointSize = 2.0 * (window.devicePixelRatio ?? 1);
    // when we resize we need to resize the background and screen textures
    twgl.resizeTexture(gl, textures.backgroundTexture, {
      width: gl.canvas.width,
      height: gl.canvas.height,
    });
    twgl.resizeTexture(gl, textures.screenTexture, {
      width: gl.canvas.width,
      height: gl.canvas.height,
    });
  }

  function updateDirectionData(directionData) {
    if (textures.u_image) {
      gl.deleteTexture(textures.u_image);
    }
    textures.u_image = twgl.createTexture(gl, {
      mag: gl.LINEAR,
      min: gl.LINEAR,
      width: directionData.dimensions[0],
      height: directionData.dimensions[1],
      format: gl.RGBA,
      src: directionData.data.data,
    });
    ({ bounds, dimensions: dataRes } = directionData);
  }

  function initialize() {
    fadeOpacity = 0.985;
    speedFactor = 0.075;
    dropRate = 0.003;
    dropRateBump = 0.05;

    programInfo = twgl.createProgramInfo(gl, [shaders.vs, shaders.fs]);
    screenProgramInfo = twgl.createProgramInfo(gl, [shaders.vsQuad, shaders.fsScreen]);
    updateProgramInfo = twgl.createProgramInfo(gl, [shaders.vsQuad, shaders.fsUpdate]);

    // initial setting of particle positions
    setParticles(nParticles);

    const emptyPixels = new Uint8Array(
      gl.canvas.width * gl.canvas.height * 4,
    );

    textures = twgl.createTextures(gl, {
      u_image: {
        mag: gl.LINEAR,
        min: gl.LINEAR,
        width: gl.canvas.width,
        height: gl.canvas.height,
        format: gl.RGBA,
        src: emptyPixels.data,
      },
      backgroundTexture: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: gl.canvas.width,
        height: gl.canvas.height,
        format: gl.RGBA,
        src: emptyPixels,
        wrap: gl.CLAMP_TO_EDGE,
      },
      screenTexture: {
        mag: gl.NEAREST,
        min: gl.NEAREST,
        width: gl.canvas.width,
        height: gl.canvas.height,
        format: gl.RGBA,
        src: emptyPixels,
        wrap: gl.CLAMP_TO_EDGE,
      },
    });

    framebuffer = gl.createFramebuffer();

    const arrays = {
      a_pos: {
        numComponents: 2,
        data: new Float32Array([0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]),
      },
    };

    quadBufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
  }

  function drawParticles() {
    gl.useProgram(programInfo.program);

    const uniforms = {
      u_vector: textures.u_image,
      u_particles: particleTextures.particleTexture0,
      u_particles_res: particleRes,
      u_point_size: pointSize,
      u_vector_min: [range[0], range[0]],
      u_vector_max: [range[1], range[1]],
      u_bounds: mapBounds,
      u_data_bounds: bounds,
    };

    twgl.setBuffersAndAttributes(gl, programInfo, particleBufferInfo);
    twgl.setUniforms(programInfo, uniforms);

    twgl.drawBufferInfo(gl, particleBufferInfo, gl.POINTS);
  }

  function drawTexture(texture, opacity) {
    gl.useProgram(screenProgramInfo.program);

    const uniforms = {
      u_screen: texture,
      u_opacity: opacity,
    };

    twgl.setBuffersAndAttributes(gl, screenProgramInfo, quadBufferInfo);
    twgl.setUniforms(screenProgramInfo, uniforms);
    twgl.drawBufferInfo(gl, quadBufferInfo);
  }

  function drawScreen() {
  // bind framebuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    // draw to screenTexture
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, textures.screenTexture, 0,
    );
    // set viewport to size of canvas

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);

    // first disable blending
    gl.disable(gl.BLEND);

    // draw backgroundTexture to screenTexture target
    drawTexture(textures.backgroundTexture, fadeOpacity);
    // draw particles to screentexture
    drawParticles();

    // target normal canvas by setting FRAMEBUFFER to null
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);

    // enable blending for final render to map
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    drawTexture(textures.screenTexture, overlayOpacity);

    gl.disable(gl.BLEND);

    // swap background with screen
    const temp = textures.backgroundTexture;
    textures.backgroundTexture = textures.screenTexture;
    textures.screenTexture = temp;
  }

  function updateParticles() {
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, particleTextures.particleTexture1, 0,
    );

    gl.viewport(0, 0, particleRes, particleRes);

    gl.useProgram(updateProgramInfo.program);

    const uniforms = {
      u_vector: textures.u_image,
      u_particles: particleTextures.particleTexture0,
      u_vector_min: [range[0], range[0]],
      u_vector_max: [range[1], range[1]],
      u_rand_seed: Math.random(),
      u_vector_res: dataRes,
      u_speed_factor: speedFactor,
      u_drop_rate: dropRate,
      u_drop_rate_bump: dropRateBump,
      u_bounds: mapBounds,
      u_data_bounds: bounds,
      u_x_scale: xScale,
    };

    twgl.setBuffersAndAttributes(gl, updateProgramInfo, quadBufferInfo);

    twgl.setUniforms(updateProgramInfo, uniforms);

    twgl.drawBufferInfo(gl, quadBufferInfo);

    const temp = particleTextures.particleTexture0;
    particleTextures.particleTexture0 = particleTextures.particleTexture1;
    particleTextures.particleTexture1 = temp;
  }

  function draw() {
    if (state !== 'ANIMATING') return;

    gl.disable(gl.DEPTH_TEST);
    gl.disable(gl.STENCIL_TEST);

    drawScreen();
    updateParticles();
  }

  function frame() {
    map.triggerRepaint();
    animationId = requestAnimationFrame(frame);
  }

  function startAnimation() {
    state = 'ANIMATING';
    setBounds(map.getBounds());
    frame();
  }

  function stopAnimation() {
    state = 'PAUSED';
    clear();
    cancelAnimationFrame(animationId);
  }

  function setData() {
  // initialize settings, programs, buffers
    initialize();

    // start animating field
    startAnimation();
  }

  function updateOpacity(opacity) {
    overlayOpacity = opacity;
  }

  function cleanup() {
    gl.deleteProgram(programInfo.program);
    gl.deleteProgram(screenProgramInfo.program);
    gl.deleteProgram(updateProgramInfo.program);
    gl.deleteFramebuffer(framebuffer.framebuffer);
    Object.values(textures).forEach((i) => {
      gl.deleteTexture(i);
    });
    Object.values(particleTextures).forEach((i) => {
      gl.deleteTexture(i);
    });
  }

  return {
    setData,
    startAnimation,
    stopAnimation,
    updateDirectionData,
    draw,
    resize,
    initialize,
    updateOpacity,
    cleanup,
  };
}
