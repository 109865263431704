<script>
import { addSource, colorInterpolate } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'AirQualityOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      filter: ['>=', ['get', 'DN'], 1],
      frames: [],
      isAllAccess: true,
      isForecast: false,
      source: {
        has_tile_index: true,
        short_name: OverlayNames.airqual,
        tile_sort: 'asc',
        tile_types: ['points'],
        use_defaults: true,
      },
    };
  },

  computed: {
    layerBelow() {
      if (this.map?.getLayer('single-locations')) {
        return 'single-locations';
      }

      if (this.map?.getLayer('settlement-minor-label')) {
        return 'settlement-minor-label';
      }

      return undefined;
    },
  },

  methods: {
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      this.tiles.forEach((tile) => this.addFrame(tile));
      this.show();
    },

    addCircles(tile) {
      const { name } = tile.index_json;

      this.map.addLayer({
        'id': name,
        'type': 'circle',
        'source': name,
        'source-layer': tile.source_layer_id,
        'filter': this.filter,
        'layout': {
          'visibility': 'none',
          'circle-sort-key': ['get', 'DN'],
        },
        'paint': {
          'circle-opacity': 0,
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 3, 4, 6, 12, 12, 26],
          'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 0, 0, 4, 1, 6, 2, 12, 3],
          'circle-stroke-color': ['interpolate', ['linear'], ['zoom'], 8, 'transparent', 10, 'white'],
          'circle-stroke-opacity': 0,
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'DN'],
            ...colorInterpolate(this.mapSource.legends[0].steps),
          ],
        },
      }, this.layerBelow);

      return { id: name, opacities: ['circle-opacity', 'circle-stroke-opacity'] };
    },

    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const source = addSource({ tile }, this);

      if (!source) {
        return;
      }

      const layer = this.addCircles(tile);
      const labels = this.addLabels(tile);

      this.frames.push({
        labels,
        layer,
        source,
        source_timestamp,
      });
    },

    addLabels(tile) {
      const { name } = tile.index_json;
      const id = `${name}-label`;

      this.map.addLayer({
        id,
        'type': 'symbol',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 6,
        'filter': this.filter,
        'layout': {
          'symbol-avoid-edges': true,
          'symbol-sort-key': ['*', ['get', 'DN'], -1],
          'symbol-placement': 'point',
          'text-field': ['concat', '', ['round', ['get', 'DN']]],
          'text-font': this.mapStyle.textFont,
          'text-rotation-alignment': 'viewport',
          'text-pitch-alignment': 'viewport',
          'text-padding': 10,
          'text-size': ['interpolate', ['linear'], ['zoom'], 6, 10, 8, 12],
          'visibility': 'none',
        },
        'paint': {
          'text-color': ['step', ['get', 'DN'], '#000', 100, '#fff'],
          'text-opacity': 0,
        },
      }, this.layerBelow);

      return { id, moveOnShow: this.layerBelow, opacities: ['text-opacity'] };
    },
  },
};
</script>
