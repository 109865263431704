<script>
import { mapState } from 'pinia';
import { addLabels, addSource } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'TemperatureOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      isForecast: true,
      source: {
        get_forecasts: false,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        ranges: [
          { start: 0, stop: 12, step: 1 },
          { start: 15, stop: 48, step: 3 },
        ],
        short_name: OverlayNames.temps,
        tile_count: 50,
        tile_sort: 'asc',
        tile_types: ['points', 'rasters'],
      },
    };
  },

  computed: {
    ...mapState(useMapStore, ['currentBaseMap']),

    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),

    textFieldLabel() {
      const imperialLabel = ['concat', ['get', 'DN'], '°'];
      const metricLabel = [
        'concat',
        [
          'round',
          [
            '*', 5 / 9,
            ['-', ['get', 'DN'], 32],
          ],
        ],
        '°',
      ];

      return ['imperial', 'us'].includes(this.units) ? imperialLabel : metricLabel;
    },
  },

  methods: {
    /**
     * Override method from mixin to render a raster layer and use the points tile for the labels
     * layer.
     */
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      this.mapSource.tiles.points.forEach((pointTile, index) => this.addFrame(pointTile, index));
      this.addOverlayMask({}, this);
      this.show();
    },

    addFrame(pointTile, index) {
      /* eslint camelcase: off */
      const labelLayerBelow = this.map.getLayer('settlement-label')
        ? 'settlement-label'
        : 'settlement-minor-label';

      const { source_timestamp } = pointTile;
      const rasterTile = this.mapSource.tiles.rasters[index];

      const pointSource = addSource({ tile: pointTile, type: 'vector' }, this);
      const rasterSource = addSource({ tile: rasterTile, type: 'raster' }, this);

      if (!pointSource || !rasterSource) {
        return;
      }

      const labels = addLabels({
        filter: true,
        layerBelow: labelLayerBelow,
        minzoom: 0,
        layout: {
          'symbol-placement': 'point',
          'text-allow-overlap': false,
          'text-ignore-placement': false,
          'text-offset': [-1.7, 0],
          'visibility': 'none',
        },
        tile: pointTile,
      }, this);
      const layer = this.addRaster({ source: rasterSource });

      const frame = {
        labels,
        layer,
        sources: [pointSource, rasterSource],
        source_timestamp,
      };

      this.frames.push(frame);
    },

    addRaster({ source }) {
      const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';

      this.map.addLayer({
        id: source,
        type: 'raster',
        source,
        layout: {
          visibility: 'visible',
        },
        paint: {
          'raster-opacity': 0,
          'raster-opacity-transition': {
            duration: 0,
            delay: 0,
          },
        },
      }, layerBelow);

      return { id: source, opacities: ['raster-opacity'] };
    },
  },
};
</script>
