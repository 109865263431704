<script>
import { mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import StaticOverlayMixin from './Mixins/StaticOverlayMixin';

export default {
  name: 'ParcelsOverlay',

  mixins: [
    StaticOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      color: '#E29E5A',
      layers: [],
      isAllAccess: true,
      sortField: 'fid',
      popup: {
        className: 'location-hover-popup',
        enabled: true,
        control: null,
        layerId: null,
        featureId: null,
        mouseEvent: null,
        timeoutId: null,
      },
      source: {
        has_legend: false,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.parcels,
        tile_sort: 'asc',
        tile_types: ['points'],
        tile_count: 1,
      },
    };
  },

  computed: {
    ...mapState(useMapStore, {
      mapCard: (state) => state.ui.mapCard,
    }),

    layerBelow() {
      if (this.map?.getLayer('single-locations')) {
        return 'single-locations';
      }

      return this.map?.getLayer('settlement-label')
        ? 'settlement-label'
        : 'settlement-minor-label';
    },
  },

  methods: {
    async add() {
      await this.checkForUpdatedMapSource();
      const [tile] = this.tiles;

      await this.createPopup();
      this.addSource({ tile });
      this.layers.push(this.addOutlineLayer(tile, this.layerBelow));
      this.layers.push(this.addLineLayer(tile, this.layerBelow));
      this.layers.push(this.addLabels(tile, this.layerBelow));
      const fillLayer = this.addFillLayer(tile, this.layerBelow);
      this.layers.push(fillLayer);
      this.addEvents(fillLayer.id);
      this.addMasks();
      this.show();
      this.handleOpacityChange();
    },

    disablePopup() {
      this.popup.enabled = false;
      this.hidePopupImmediate();
    },

    enablePopup() {
      this.popup.enabled = true;
    },

    addEvents(layerId) {
      this.map.on('popup.show', this.disablePopup);
      this.map.on('popup.hide', this.enablePopup);

      this.map.on('click', this.hidePopupImmediate);
      this.map.on('movestart', this.hidePopupImmediate);
      this.map.on('click', [layerId], this.showPopup);
      this.popupLayer = layerId;
    },

    removeEvents() {
      this.map.off('popup.show', this.disablePopup);
      this.map.off('popup.hide', this.enablePopup);

      this.map.off('click', this.hidePopupImmediate);
      this.map.off('movestart', this.hidePopupImmediate);

      if (this.popupLayer) {
        this.hidePopup();

        this.map.off('click', [this.popupLayer], this.showPopup);
      }
      this.popupLayer = null;
    },

    async createPopup() {
      this.popup.control = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        maxWidth: '300px',
        offset: 10,
        className: this.popup.className,
      });
    },

    clearPopupTimeout() {
      if (!this.popup.timeoutId) {
        return;
      }

      window.clearTimeout(this.popup.timeoutId);
      this.popup.timeoutId = null;
    },

    htmlPopup(sourceLayer, feature) {
      const { properties: props } = feature;

      return `
        <div style="min-width: 240px">
          <div class="tw-text-center">${props.owner}</div>
        </div>
      `;
    },

    showPopup(e) {
      // There's a bunch of extra logic here to deal with the fact that some parcels
      // don't have an owner, and that there can be multiple overlapping parcels with
      // the same geometry. For the labels we're just relying on mapbox getting rid of
      // overlapping labels. So we need to sort by the same field we're using to sort labels.
      // if there's just one feature and it doesn't have an owner we need to add a dummy field
      // to make it so that the UX isn't confusing. If we move to hover over labels then
      // sorting and doeing all this might get slow but if we're only showing popups on clicks then
      // we're probably fine

      // at one point i was doing a deepclone of e.features here
      // but that was noticeably slow... so beware
      let { features } = e;
      if (features.length === 1) {
        features.forEach((f, index) => {
          if (f.properties.owner === undefined) {
            const modifiable = features[index];
            modifiable.properties.owner = 'No Owner Information';
          }
        });
      }
      else {
        features = features.filter((f) => f.properties.owner !== undefined);
      }
      // need to make sure we show the popup for the same parcel we're showing the label for
      // note that this will not really work if we start using strings for the sortField
      features.sort((a, b) => b.properties[this.sortField] - a.properties[this.sortField]);
      const [feature] = features;
      const layerId = feature.layer.id;
      const sourceLayer = feature.layer['source-layer'];
      this.popup.mouseEvent = e.originalEvent;

      // this should cover features that are created both with and without GDAL/OGR
      // since GDAL/OGR puts the ID on the feature, not in the properties
      const featureId = feature.id ?? feature.properties.id;

      if (!this.popup.enabled
        || (this.popup.featureId === featureId && layerId === this.popup.layerId)
      ) {
        return;
      }

      const show = () => {
        this.clearPopupTimeout();
        this.popup.control.setHTML(this.htmlPopup(sourceLayer, feature));

        if (this.popup.featureId !== feature.properties.id) {
          this.popup.control
            .setLngLat(e.lngLat)
            .addTo(this.map);
        }

        this.popup.featureId = featureId;
        this.popup.layerId = layerId;
      };

      window.setTimeout(() => {
        if (e.originalEvent.handledBy) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }
        e.originalEvent.handledBy = this.$options.name;
        show();

        const popup = this.map.getContainer().querySelector(`.${this.popup.className}`);
        const closeHandler = (clickEvent) => {
          this.hidePopupImmediate(clickEvent);
          popup.removeEventListener('click', closeHandler);
        };
        popup.addEventListener('click', closeHandler);
      }, 50);
    },

    hidePopup() {
      if (this.popup.timeoutId) {
        return;
      }

      this.popup.timeoutId = setTimeout(this.hidePopupImmediate, 250);
    },

    hidePopupImmediate() {
      if (this.popup.control.isOpen()) {
        this.popup.control.remove();
      }

      this.popup.featureId = null;
      this.popup.layerId = null;
      this.popup.mouseEvent = null;
      this.popup.timeoutId = null;
    },

    addLabels(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-id`;
      // const textColor = 'hsl(26, 20%, 36%)';
      // const textColorAlt = 'hsl(0, 0%, 78%)';
      // const textHalo = 'hsla(35, 16%, 100%, 0.75)';
      // const textHaloAlt = 'hsla(0, 0%, 13%, 0.75)';

      this.map.addLayer({
        id,
        'type': 'symbol',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 15,
        'layout': {
          'symbol-sort-key': ['-', ['get', this.sortField]],
          'text-field': ['get', 'owner'],
          'text-font': this.mapStyle.textFont,
          'text-padding': 7,
          'text-size': 11,
          'text-opacity': 0,
          'visibility': 'none',
        },
        'paint': {
          'text-color': this.mapStyle.textColor,
          'text-halo-color': this.mapStyle.textHaloColor,
          'text-halo-blur': this.mapStyle.textHaloBlur,
          'text-halo-width': this.mapStyle.textHaloWidth,
        },
      }, layerBelow);

      return {
        id, moveToTop: true, opacities: ['text-opacity'], opacityValues: [1.0],
      };
    },

    addOutlineLayer(tile, layerBelow) {
      const { name } = tile.index_json;

      const id = `${name}_outline`;

      this.map.addLayer({
        id,
        'type': 'line',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 11,
        'layout': {
          visibility: 'none',
        },
        'paint': {
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 11, 0.5, 18, 0.9],
          'line-color': '#FFFFFF',
          'line-width': ['interpolate', ['linear'], ['zoom'], 11, 0.8, 13, 3.2, 18, 4.8],
        },
      }, layerBelow);

      const outlineOpacityFunction = (opacity) => ['interpolate', ['linear'], ['zoom'], 11, ['*', 0.5, opacity], 18, ['*', 0.9, opacity]];

      return { id, opacities: ['line-opacity'], opacityValues: [outlineOpacityFunction] };
    },

    addLineLayer(tile, layerBelow) {
      const { name } = tile.index_json;

      this.map.addLayer({
        'id': name,
        'type': 'line',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 11,
        'layout': {
          visibility: 'none',
        },
        'paint': {
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 11, 0.5, 18, 0.9],
          'line-color': this.color,
          'line-width': ['interpolate', ['linear'], ['zoom'], 11, 0.3, 13, 1.2, 18, 1.8],
        },
      }, layerBelow);

      const lineOpacityFunction = (opacity) => ['interpolate', ['linear'], ['zoom'], 11, ['*', 0.5, opacity], 18, ['*', 0.9, opacity]];

      return { id: name, opacities: ['line-opacity'], opacityValues: [lineOpacityFunction] };
    },

    // empty fill layer for popups
    addFillLayer(tile, layerBelow) {
      const { name } = tile.index_json;

      const layerId = `${name}_fill`;
      this.map.addLayer({
        'id': layerId,
        'type': 'fill',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 11,
        'layout': {
          visibility: 'none',
        },
        'paint': {
          'fill-opacity': 0,
          'line-color': this.color,
        },
      }, layerBelow);

      return { id: layerId, opacities: [] };
    },
  },
};
</script>
