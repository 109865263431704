<script>
import { mapActions, mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import { addImages, colorInterpolate, hasImages } from '@@/components/Maps/Overlays/Utils';
import { chartColors } from '@/utils/ChartUtils';
import { useUiStore } from '@/stores/Ui.js';
import OpacityMixin from '@/components/Maps/Overlays/Mixins/OpacityMixin';
import OverlayMixin from '@/components/Maps/Overlays/Mixins/OverlayMixin';
import OverlayNames from '@/components/Maps/Overlays/OverlayNames';
import StaticOverlayMixin from '@/components/Maps/Overlays/Mixins/StaticOverlayMixin';

const images = [
  {
    id: 'wind-arrow-small',
    src: 'https://blizzard.opensnow.com/maps/icons/wind-arrow-small.svg',
    width: 30,
    height: 36,
  },
];

export default {
  name: 'WeatherStationsOverlay',

  mixins: [
    OpacityMixin,
    OverlayMixin,
    StaticOverlayMixin,
  ],

  props: {
    fieldName: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      labelLayerId: 'weather-stations-labels',
      layers: [],
      mapSourceId: null,
      popup: null,
      popupTimeout: null,
      source: {
        get_forecasts: false,
        has_legend: true,
        has_tile_index: true,
        short_name: OverlayNames.weatherStations,
        tile_count: 1,
        tile_sort: 'asc',
        tile_types: ['points'],
        use_defaults: true,
      },
      staleCheckInterval: null,
      symbolLayerId: 'weather-stations-symbols',
    };
  },

  computed: {
    ...mapState(useMapStore, {
      overlayFieldControlOptions: (state) => state.ui.overlayFieldControlOptions,
      overlayFieldControlValue: (state) => state.ui.overlayFieldControlValue,
    }),
    ...mapState(useMapStore, ['currentOverlayLegend']),
    ...mapState(useUserStore, { units: (state) => state.preferences.units }),
    ...mapState(useUserStore, ['isAllAccess']),

    isPrecipSnow() {
      return [
        'snow_depth',
        'new_snow_6_hour',
        'new_snow_12_hour',
        'new_snow_24_hour',
        'new_snow_48_hour',
        'new_snow_72_hour',
        'new_snow_96_hour',
        'new_snow_120_hour',
      ].includes(this.weatherStationFieldName);
    },

    isSwe() {
      return this.weatherStationFieldName === 'swe';
    },

    isTemp() {
      return this.weatherStationFieldName === 'temp';
    },

    isWind() {
      return ['wind_speed', 'wind_gust_speed'].includes(this.weatherStationFieldName);
    },

    layerBelow() {
      if (this.map?.getLayer('single-locations')) {
        return 'single-locations';
      }

      return this.map?.getLayer('settlement-label')
        ? 'settlement-label'
        : 'settlement-minor-label';
    },

    weatherStationFieldName() {
      if (this.isMiniMap) {
        return this.fieldName;
      }

      return this.overlayFieldControlValue;
    },
  },

  watch: {
    isActive(newValue) {
      if (!newValue) {
        this.handleRemove();
      }
    },

    overlayFieldControlValue() {
      if (this.isActive) {
        this.refresh();
      }
    },

    units() {
      if (this.isActive) {
        this.refresh();
      }
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties', 'updateMapSource']),
    ...mapActions(useUiStore, ['setUiProperties']),

    async add() {
      await this.checkForUpdatedMapSource();

      if (!hasImages(images, this.map)) {
        await addImages(images, this.map);
      }

      this.setOverlayFieldControlState();

      if (this.layers.length > 0) {
        this.addEvents();
        this.show();
        this.startStaleCheckInterval();
        this.checkForStaleTiles();
        return;
      }

      const [tile] = this.tiles;

      this.addSource({ tile });
      this.addCircles({ tile });
      this.addLabels({ tile });
      await this.createPopup();
      this.addEvents();
      this.show();
      this.startStaleCheckInterval();
    },

    addCircles({ tile }) {
      this.map.addLayer({
        'id': this.symbolLayerId,
        'filter': this.getFilterExpression(),
        'type': 'circle',
        'source': this.mapSourceId,
        'source-layer': tile.source_layer_id,
        'layout': {
          'visibility': 'none',
          'circle-sort-key': this.getCircleSortKeyExpression(),
        },
        'paint': {
          'circle-color': this.getCircleColorExpression(),
          'circle-opacity': 0,
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 3, 4, 6, 12, 12, 26],
          'circle-stroke-color': this.getCircleStrokeColorExpression(),
          'circle-stroke-opacity': 0,
          'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 0, 0, 4, 1, 6, 2, 12, 3],
        },
      }, this.layerBelow);

      this.layers.push({
        id: this.symbolLayerId,
        opacities: ['circle-opacity', 'circle-stroke-opacity'],
      });
    },

    addEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.on('popup.show', this.handlePopupShow);

      this.layers.forEach((layer) => {
        const { id } = layer;

        this.map.on('click', id, this.handleClick);

        if (!this.$device.isMobileOrTablet) {
          this.map.on('mouseenter', id, this.showPopup);
          this.map.on('mouseleave', id, this.hidePopup);
        }
      });
    },

    addLabels({ tile }) {
      const layer = {
        'id': this.labelLayerId,
        'filter': this.getFilterExpression(),
        'type': 'symbol',
        'source': this.mapSourceId,
        'source-layer': tile.source_layer_id,
        'minzoom': 5,
        'layout': {
          'icon-image': this.getIconImage(),
          'icon-rotate': this.getIconRotate(),
          'icon-size': this.getIconSize(),
          'symbol-avoid-edges': true,
          'symbol-placement': 'point',
          'symbol-sort-key': this.getSymbolSortKeyExpression(),
          'text-field': this.getTextFieldExpression(),
          'text-font': this.mapStyle.textFont,
          'text-offset': this.getTextOffsetExpression(),
          'text-padding': 10,
          'text-pitch-alignment': 'viewport',
          'text-rotation-alignment': 'viewport',
          'text-size': ['interpolate', ['linear'], ['zoom'], 0, 0, 3, 10, 6, 11, 12, 12],
        },
        'paint': {
          'icon-opacity': 0,
          'icon-translate': this.getIconTranslate(),
          'text-color': this.mapStyle.textColor,
          'text-halo-blur': 0.5,
          'text-halo-color': '#000000',
          'text-halo-width': 0.5,
          'text-opacity': 0,
        },
      };

      this.map.addLayer(layer, this.layerBelow);

      this.layers.push({
        id: this.labelLayerId,
        opacities: ['icon-opacity', 'text-opacity'],
      });
    },

    /**
     * Override method from StaticOverlayMixin to always use the name "weather-stations" for this
     * map source. This is done so that the tiles URL can be updated when a new tile set is
     * available on the server so that the WeatherStationsOverlay can be re-rendered to display
     * the new data points without removing the overlay and adding it back. The
     * startStaleCheckInterval() method queries the server every 60s to check for an updated
     * tile set.
     *
     * SEE:
     * - https://github.com/mapbox/mapbox-gl-js/pull/8048
     * - https://stackoverflow.com/questions/38631344/recommended-way-to-switch-tile-urls-in-mapbox-gl-js
     */
    addSource({ tile }) {
      this.mapSourceId = 'weather-stations';

      try {
        this.map.addSource(this.mapSourceId, {
          ...tile.index_json,
          type: 'vector',
        });
      }
      catch (e) {
        // Do nothing
      }
    },

    /**
     * When an updated map source is found, then update the map source tiles URL which will force
     * the map to re-render the layers associated with the updated source.
     * SEE: https://docs.mapbox.com/mapbox-gl-js/api/sources/#vectortilesource#settiles
     */
    async checkForStaleTiles() {
      /* eslint no-console: off */
      const oldTilesUrl = this.tiles[0].index_json.tiles[0];
      const wasUpdated = await this.fetchMapSourcesTiles({ source: this.source });

      if (wasUpdated) {
        this.map
          .getSource(this.mapSourceId)
          .setTiles(this.tiles[0].index_json.tiles);
      }

      // DEBUG
      const method = `${this.$options.name}.checkForStaleTiles()`;
      const message = `${method}: tiles ${wasUpdated ? 'were' : 'were not'} updated`;
      console.log(message);

      if (wasUpdated) {
        const newTilesUrl = this.tiles[0].index_json.tiles[0];
        console.log(`${method}: old tiles = ${oldTilesUrl}, new tiles = ${newTilesUrl}`);
      }
    },

    clearStaleCheckInterval() {
      window.clearInterval(this.staleCheckIntervalId);
      this.staleCheckIntervalId = null;
    },

    async createPopup() {
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: 'none',
        offset: 10,
        className: 'location-hover-popup',
      });
    },

    getCircleColorExpression() {
      if (!this.currentOverlayLegend) {
        return chartColors.unknown;
      }

      const circleColorExpression = [
        'interpolate',
        ['linear'],
        ['get', this.weatherStationFieldName],
        ...colorInterpolate(this.currentOverlayLegend.steps),
      ];

      return ['case',
        this.isFieldStale(this.weatherStationFieldName), chartColors.unknown,
        circleColorExpression,
      ];
    },

    getCircleSortKeyExpression() {
      return ['case',
        this.isFieldStale(this.weatherStationFieldName), -1000,
        ['get', this.weatherStationFieldName],
      ];
    },

    getCircleStrokeColorExpression() {
      return ['case',
        this.isFieldFlagged(this.weatherStationFieldName), '#000000',
        '#ffffff',
      ];
    },

    getFilterExpression() {
      return ['any',
        ['==', ['typeof', ['get', this.weatherStationFieldName]], 'number'],
        this.isFieldStale(this.weatherStationFieldName),
      ];
    },

    getIconImage() {
      return this.isWind
        ? [
            'case',
            ['==', ['typeof', ['get', 'wind_dir']], 'number'],
            'wind-arrow-small',
            '',
          ]
        : '';
    },

    getIconRotate() {
      return this.isWind ? ['get', 'wind_dir'] : 0;
    },

    /**
     * When showing wind increase the icon size as the user zooms in.
     */
    getIconSize() {
      return this.isWind
        ? ['interpolate', ['linear'], ['zoom'], 5, 0.6, 15, 1.1]
        : 0;
    },

    /**
     * When showing wind translate the icon down away from its anchor
     */
    getIconTranslate() {
      return this.isWind
        ? ['interpolate', ['linear'], ['zoom'],
            5, ['literal', [0, 6]],
            15, ['literal', [0, 9]],
          ]
        : [0, 0];
    },

    getPrecipSnowExpression(field) {
      const imperialLabel = [
        'concat',
        [
          'round',
          ['get', field],
        ],
        '',
      ];

      const metricLabel = [
        'concat',
        [
          'max',
          1,
          ['*', ['round', ['/', ['*', 2.54, ['get', field]], 5]], 5],
        ],
        '',
      ];

      return ['case',
        this.isFieldStale(field), '--',
        this.units === 'imperial' ? imperialLabel : metricLabel,
      ];
    },

    /**
     * Round snow water equivalent to the nearest decimal place.
     * SEE: https://stackoverflow.com/questions/7342957/how-do-you-round-to-one-decimal-place-in-javascript
     */
    getSweExpression(field) {
      const imperialLabel = [
        'concat',
        ['/', ['round', ['*', ['get', field], 10]], 10],
        '',
      ];

      const metricLabel = [
        'concat',
        ['/', ['round', ['*', ['*', ['get', field], 2.54], 10]], 10],
        '',
      ];

      return ['case',
        this.isFieldStale(field), '--',
        this.units === 'imperial' ? imperialLabel : metricLabel,
      ];
    },

    getSymbolSortKeyExpression() {
      return ['case',
        this.isFieldStale(this.weatherStationFieldName), 1000,
        ['*', ['get', this.weatherStationFieldName], -1],
      ];
    },

    getTemperatureExpression(field) {
      const imperialLabel = [
        'concat',
        [
          'round',
          ['get', field],
        ],
        '',
      ];

      const metricLabel = [
        'concat',
        [
          'round',
          [
            '*', 5 / 9,
            ['-', ['get', field], 32],
          ],
        ],
        '',
      ];

      return ['case',
        this.isFieldStale(field), '--',
        this.units === 'imperial' ? imperialLabel : metricLabel,
      ];
    },

    getTextFieldExpression() {
      if (this.isTemp) {
        return this.getTemperatureExpression(this.weatherStationFieldName);
      }

      if (this.isSwe) {
        return this.getSweExpression(this.weatherStationFieldName);
      }

      if (this.isPrecipSnow) {
        return this.getPrecipSnowExpression(this.weatherStationFieldName);
      }

      if (this.isWind) {
        return this.getWindExpression(this.weatherStationFieldName);
      }

      return '';
    },

    /**
     * When showing wind shift the text up if the wind direction is a number to allow for the wind
     * direction icon to be placed below the text.
     */
    getTextOffsetExpression() {
      if (this.isWind) {
        const startExpression = [
          'case',
          ['==', ['typeof', ['get', 'wind_dir']], 'number'],
          ['literal', [0.0, -0.6]],
          ['literal', [0.0, 0.0]],
        ];

        const endExpression = [
          'case',
          ['==', ['typeof', ['get', 'wind_dir']], 'number'],
          ['literal', [0.0, -0.8]],
          ['literal', [0.0, 0.0]],
        ];

        return ['interpolate', ['linear'], ['zoom'], 5, startExpression, 15, endExpression];
      }

      return [0, 0];
    },

    /**
     * @todo Move this to /components/Maps/Overlays/Utils.js and use in <WindOverlay>
     */
    getWindExpression(field) {
      const imperialLabel = [
        'concat',
        ['round', ['get', field]],
        '',
      ];

      const metricLabel = [
        'concat',
        ['round', ['*', 1.609, ['get', field]]],
        '', // ' kmh'
      ];

      return ['case',
        this.isFieldStale(field), '--',
        this.units === 'imperial' ? imperialLabel : metricLabel,
      ];
    },

    handleClick(e) {
      const { features } = e;
      e.originalEvent.stopImmediatePropagation();

      window.setTimeout(() => {
        // Ignore the event if an upper layer, i.e. the Locations Overlay, has already handled it!
        // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783

        if (e.originalEvent.handledBy) {
          return;
        }

        e.originalEvent.handledBy = this.$options.name;

        const { stid: weatherStationSlug } = features[0].properties;
        this.setUiProperties({ weatherStationSlug });
      }, 100);
    },

    handlePopupShow(data) {
      if (data.overlay === this) {
        return;
      }

      this.hidePopup();
    },

    handleRemove() {
      this.popup?.remove();
      this.clearStaleCheckInterval();
      this.removeEvents();
      this.setMapUiProperties({ overlayFieldControlOptions: null });
    },

    hide() {
      this.clearStaleCheckInterval();
      this.layers.forEach((layer) => this.map.setLayoutProperty(layer.id, 'visibility', 'none'));
    },

    hidePopup() {
      this.popupTimeout = setTimeout(() => this.popup.remove(), 250);
    },

    isFieldFlagged(field) {
      return ['==', ['get', `${field}_flagged`], true];
    },

    isFieldStale(field) {
      return ['==', ['get', `${field}_stale`], true];
    },

    refresh() {
      this.map.setFilter(this.symbolLayerId, this.getFilterExpression());
      this.map.setLayoutProperty(this.symbolLayerId, 'circle-sort-key', this.getCircleSortKeyExpression());
      this.map.setPaintProperty(this.symbolLayerId, 'circle-color', this.getCircleColorExpression());
      this.map.setPaintProperty(this.symbolLayerId, 'circle-stroke-color', this.getCircleStrokeColorExpression());

      this.map.setFilter(this.labelLayerId, this.getFilterExpression());
      this.map.setLayoutProperty(this.labelLayerId, 'icon-image', this.getIconImage());
      this.map.setLayoutProperty(this.labelLayerId, 'icon-rotate', this.getIconRotate());
      this.map.setLayoutProperty(this.labelLayerId, 'icon-size', this.getIconSize());
      this.map.setLayoutProperty(this.labelLayerId, 'symbol-sort-key', this.getSymbolSortKeyExpression());
      this.map.setLayoutProperty(this.labelLayerId, 'text-field', this.getTextFieldExpression());
      this.map.setLayoutProperty(this.labelLayerId, 'text-offset', this.getTextOffsetExpression());
      this.map.setPaintProperty(this.labelLayerId, 'icon-translate', this.getIconTranslate());
    },

    removeEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.off('popup.show', this.handlePopupShow);

      this.layers.forEach((layer) => {
        const { id } = layer;

        this.map.off('click', id, this.handleClick);

        if (!this.$device.isMobileOrTablet) {
          this.map.off('mouseenter', id, this.showPopup);
          this.map.off('mouseleave', id, this.hidePopup);
        }
      });
    },

    setOverlayFieldControlState() {
      /* eslint camelcase: off */
      const overlayFieldControlOptions = this.mapSource.fields
        .map((field) => {
          const {
            icon_url,
            is_all_access,
            label,
            name,
          } = field;

          return {
            label,
            icon: icon_url,
            is_all_access,
            value: name,
          };
        });

      // Ensure that an overlayFieldControlValue that was set in the store is valid by checking
      // that it is present in the control options values!

      const isValid = (fieldControlValue) => {
        const overlayFieldControlOption = overlayFieldControlOptions
          .find(({ value }) => value === fieldControlValue);

        if (overlayFieldControlOption) {
          if (overlayFieldControlOption.is_all_access && this.isAllAccess) {
            return true;
          }
        }

        return false;
      };

      const overlayFieldControlValue = isValid(this.weatherStationFieldName)
        ? this.weatherStationFieldName
        : overlayFieldControlOptions.find(
          (fieldControlOption) => !fieldControlOption.is_all_access,
        ).value;

      if (this.isMiniMap) {
        this.setMapUiProperties({ overlayFieldControlOptions });
      }
      else {
        this.setMapUiProperties({
          overlayFieldControlOptions,
          overlayFieldControlValue,
        });
      };
    },

    showPopup(e) {
      window.clearTimeout(this.popupTimeout);

      this.map.fire('popup.show', {
        ...e,
        overlay: this,
      });

      this.popup
        .setLngLat(e.features[0].geometry.coordinates)
        .setHTML(e.features[0].properties.name)
        .addTo(this.map);
    },

    /**
     * Start an interval to check for stale map sources.
     */
    startStaleCheckInterval() {
      this.staleCheckIntervalId = window.setInterval(this.checkForStaleTiles, 60000);
    },
  },
};
</script>
