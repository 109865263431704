<script>
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'SmokeSurfaceOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      filter: ['>=', ['get', 'DN'], 1],
      frames: [],
      isAllAccess: true,
      isForecast: true,
      opacityTransition: 250,
      source: {
        get_forecasts: true,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        ranges: this.tileRangesHrrr(0),
        short_name: OverlayNames.smokeLow,
        tile_count: 50,
        tile_sort: 'asc',
        tile_types: ['contours'],
      },
    };
  },
};
</script>
