<script>
import mapboxgl from 'mapbox-gl';
import { addSource, colorInterpolate } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

const acreFormat = new Intl.NumberFormat(undefined, {
  maximumSignificantDigits: 5,
  maximumFractionDigits: 0,
});

export default {
  name: 'WildfireOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      isForecast: false,
      popup: {
        className: 'location-hover-popup',
        enabled: true,
        control: null,
        layerId: null,
        featureId: null,
        mouseEvent: null,
        timeoutId: null,
      },
      source: {
        has_tile_index: true,
        short_name: OverlayNames.wildfire,
        tile_types: ['points'],
        tile_sort: 'asc',
        use_defaults: true,
      },
    };
  },

  methods: {
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      await this.createPopup();
      this.tiles.forEach((tile) => this.addFrame(tile));
      this.addOverlayMask({ geomOnly: true });
      // canada goes on forever and makes the 'zoom to layer'
      // functionality kinda bad if we use the real data here
      // so we'll fudge it. This is preferable to changing the real
      // data because if we alter the data, the banner won't always display
      // when it should, or may display when it shouldn't. If we have
      // to do this again on another layer we should think about a
      // nicer way to do it. (also maybe think about it when we do it on mobile)
      if (this.overlayMask) {
        this.overlayMask.coverage[1] = 18.0;
        this.overlayMask.coverage[3] = 72.0;
      }
      this.addEvents();
      this.show();
    },

    hotspotRadiusAtZoom(multiplier = 1) {
      // Circle radii should be accurately sized based on the resolution of the satellite image
      // This size should remain accurate relative to features on the ground at all zoom levels
      const RADIUS_EQUATOR_METERS = 6378137; // in WGS 84 https://en.wikipedia.org/wiki/Earth_ellipsoid
      const TILE_PIXELS = 256;
      const steps = [];

      for (let i = 0; i < 24; i += 2) {
        // keep it simple at low zooms, things are too small to see
        // if based on accurate meters-on-the-ground representation
        if (i <= 8) {
          steps.push([
            i,
            ['*', Math.min(i, 6), multiplier],
          ]);
          continue;
        }

        // https://learn.microsoft.com/en-us/bingmaps/articles/bing-maps-tile-system?redirectedfrom=MSDN#ground-resolution-and-map-scale
        // Base calculation returns # of meters per pixel on the map at a given zoom level
        // The # of meters per pixel must be divided by the individual feature's grid_size_meters
        // property because different satellites have different resolutions
        steps.push([
          i,
          ['/',
            ['*', multiplier, ['get', 'grid_size_meters']],
            ['/',
              ['*',
                ['cos',
                  ['*', ['get', 'latitude'], ['/', Math.PI, 180]],
                ],
                2,
                Math.PI,
                RADIUS_EQUATOR_METERS,
              ],
              ['*', TILE_PIXELS, ['^', 2, i]],
            ],
          ],
        ]);
      }

      return [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        ...steps.flat(),
      ];
    },

    addHotspots(tile) {
      const { name } = tile.index_json;
      const sourceLayer = 'hotspots';
      const layerIdBoundary = `${name}-hotspots-boundary`;
      const layerIdCore = `${name}-hotspots-core`;
      const layerIdFlame = `${name}-hotspots-flame`;
      const layerIdBelow = 'waterway-label';

      const colorBorderDark = '#FAEAB0';
      const colorBorderLight = '#D6C0A8';
      const colorFlameFill = '#FFFF00';

      this.map.addLayer({
        'id': layerIdBoundary,
        'type': 'circle',
        'source': name,
        'source-layer': sourceLayer,
        'layout': {
          'visibility': 'none',
          'circle-sort-key': ['*', -1, ['get', 'age_hours']],
        },
        'paint': {
          'circle-opacity': 0,
          'circle-blur': 0,
          'circle-radius': this.hotspotRadiusAtZoom(1),
          'circle-stroke-width': 0.5,
          'circle-stroke-color': this.currentBaseMap.is_dark ? colorBorderDark : colorBorderLight,
          'circle-stroke-opacity': 0,
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'age_hours'],
            ...colorInterpolate(this.mapSource.legends[0].steps),
          ],
        },
      }, layerIdBelow);

      this.map.addLayer({
        'id': layerIdCore,
        'type': 'circle',
        'source': name,
        'source-layer': sourceLayer,
        'layout': {
          'visibility': 'none',
          'circle-sort-key': ['*', -1, ['get', 'age_hours']],
        },
        'paint': {
          'circle-opacity': 0,
          'circle-blur': 0.75,
          'circle-radius': this.hotspotRadiusAtZoom(1),
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'age_hours'],
            ...colorInterpolate(this.mapSource.legends[0].steps),
          ],
        },
      }, layerIdBelow);

      this.map.addLayer({
        'id': layerIdFlame,
        'type': 'circle',
        'source': name,
        'source-layer': sourceLayer,
        'minzoom': 10,
        'filter': ['<=', ['get', 'age_hours'], 48],
        'layout': {
          'visibility': 'none',
          'circle-sort-key': ['*', -1, ['get', 'age_hours']],
        },
        'paint': {
          'circle-opacity': 0,
          'circle-blur': 1,
          'circle-radius': this.hotspotRadiusAtZoom(0.45),
          'circle-color': colorFlameFill,
        },
      }, layerIdBelow);

      // intensity of color (via opacity) grows with fire radiative power,
      // 100frp being "full intensity"
      const frpRamp = ['max', 0.25, ['min', 1, ['/', ['get', 'fire_radiative_power'], 100]]];
      return [
        { id: layerIdBoundary,
          opacities: ['circle-opacity', 'circle-stroke-opacity'],
          opacityValues: [(opacity) => ['*', opacity, 0.7], (opacity) => ['*', opacity, 0.7]] },
        {
          id: layerIdCore,
          opacities: ['circle-opacity'],
          opacityValues: [(maxOpacity = 1) => ['*', maxOpacity, frpRamp]],
        },
        {
          id: layerIdFlame,
          opacities: ['circle-opacity'],
          opacityValues: [(maxOpacity = 0.9) => [
            'interpolate',
            ['linear'],
            ['get', 'age_hours'],
            // 0, maxOpacity, 12, 0.2, 48, 0,
            0, ['*', maxOpacity, frpRamp], 12, 0.2, 48, 0,
          ]],
        }];
    },

    addPerimeters(tile, layerIdHotspot) {
      const { name } = tile.index_json;
      const sourceLayer = 'perimeters';
      const layerFillId = `${name}-perimeters-fill`;
      const layerLineId = `${name}-perimeters-line`;
      const layerIdBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'road-primary';

      const colorFill = '#FFA500';
      const colorBorderDark = '#FF5733';
      const colorBorderLight = '#A45729';

      this.map.addLayer({
        'id': layerFillId,
        'type': 'fill',
        'source': name,
        'source-layer': sourceLayer,
        'minzoom': 5,
        'layout': {
          'visibility': 'none',
          'fill-sort-key': ['case', ['to-boolean', ['get', 'is_estimate']], 0, 1],
        },
        'paint': {
          'fill-color': colorFill,
          'fill-opacity': 0,
        },
      }, layerIdBelow);

      // add perimeter outline on top of hotspots so it is not obscured by hotspot circles
      this.map.addLayer({
        'id': layerLineId,
        'type': 'line',
        'source': name,
        'source-layer': sourceLayer,
        'minzoom': 5,
        'layout': {
          'visibility': 'none',
          'line-join': 'round',
        },
        'paint': {
          'line-color': this.currentBaseMap.is_dark ? colorBorderDark : colorBorderLight,
          'line-width': ['interpolate', ['linear'], ['zoom'], 0, 2, 15, 4],
          'line-opacity': 0,
          'line-dasharray': [
            'case',
            ['to-boolean', ['get', 'is_estimate']],
            ['literal', [2, 2]],
            ['literal', [1, 0]],
          ],
        },
      }, layerIdHotspot);

      return [
        { id: layerFillId, opacities: ['fill-opacity'], opacityValues: [(opacity) => ['*', 0.25, opacity]] },
        { id: layerLineId, opacities: ['line-opacity'], opacityValues: [(opacity) => ['*', 0.85, opacity]] },
      ];
    },

    addFires(tile) {
      const { name } = tile.index_json;
      const layerId = `${name}-fires`;
      const colorLightGray = '#DDDDDD';
      const colorDarkGray = '#999999';

      // ramp initial zoomed-out view based on size of fire, to highlight larger fires
      const sizeAcresRamp = ['min', 15, ['*', 1, ['/', ['coalesce', ['get', 'size_acres'], 0.001], 5000]]];

      this.map.addLayer({
        'id': layerId,
        'type': 'circle',
        'source': name,
        'source-layer': 'fires',
        'layout': {
          'visibility': 'none',
          'circle-sort-key': ['coalesce', ['get', 'size_acres'], 1],
        },
        'paint': {
          'circle-opacity': 0,
          'circle-blur': 0,
          'circle-radius': [
            'interpolate',
            ['linear'], ['zoom'],
            0, sizeAcresRamp,
            2, sizeAcresRamp,
            // because we have perimeters and hotspots to denote actual burn areas,
            // ramp this to a consistent size when zooming in
            8, 8,
          ],
          'circle-color': this.currentBaseMap.is_dark ? colorDarkGray : colorLightGray,
          'circle-stroke-color': this.currentBaseMap.is_dark ? colorLightGray : colorDarkGray,
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 0,
        },
      });

      return [
        {
          id: layerId,
          opacities: ['circle-opacity', 'circle-stroke-opacity'],
          opacityValues: [(opacity) => ['*', opacity, 0.85], (opacity) => opacity] },
      ];
    },

    addLabels(tile) {
      const { name } = tile.index_json;
      const layerId = `${name}-label`;

      this.map.addLayer({
        'id': layerId,
        'type': 'symbol',
        'source': name,
        'source-layer': 'fires',
        'minzoom': 5,
        'layout': {
          'symbol-avoid-edges': true,
          'symbol-placement': 'point',
          'symbol-sort-key': ['*', -1, ['coalesce', ['get', 'size_acres'], 1]],
          'text-anchor': 'top',
          'text-field': ['get', 'name'],
          'text-font': this.mapStyle.textFont,
          'text-offset': [0, 1],
          'text-padding': 1,
          'text-pitch-alignment': 'viewport',
          'text-rotation-alignment': 'viewport',
          'text-size': this.mapStyle.textSize,
          'visibility': 'none',
        },
        'paint': {
          'text-color': this.mapStyle.textColor,
          'text-halo-color': this.mapStyle.textHaloColor,
          'text-halo-blur': this.mapStyle.textHaloBlur,
          'text-halo-width': this.mapStyle.textHaloWidth,
          'text-opacity': 0,
        },
      });

      return [{
        id: layerId,
        moveToTop: true,
        opacities: ['text-opacity'],
        opacityValues: [(opacity) => ['*', opacity, 0.9]],
      }];
    },

    async createPopup() {
      this.popup.control = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        maxWidth: '300px',
        offset: 10,
        className: this.popup.className,
      });
    },

    clearPopupTimeout() {
      if (!this.popup.timeoutId) {
        return;
      }

      window.clearTimeout(this.popup.timeoutId);
      this.popup.timeoutId = null;
    },

    htmlPopupHotspots(sourceLayer, feature) {
      const dateTimeFormat = 'ddd MMM D, h:mm a z';
      const { properties: props } = feature;

      if (sourceLayer === 'hotspots') {
        const timestamp = (this.currentFrame === this.frames.length - 1 && props.age_hours <= 48)
          ? `${props.age_hours} hours ago`
          : this.$dayjs(props.timestamp).format(dateTimeFormat);

        return `
          <div style="min-width: 240px">
            <div class="tw-mb-1 tw-pr-6 tw-text-base tw-font-bold tw-whitespace-nowrap" style="border-bottom: 1px solid #ccc;">Satellite Detected Hotspot</div>
            Detected ${timestamp}
          </div>
          `;
      }

      const stats = [];

      if (typeof props.size_acres === 'number') {
        stats.push(`${acreFormat.format(props.size_acres)} ${props.size_acres === 1 ? 'acre' : 'acres'}`);
      }

      if (typeof props.containment_percent === 'number') {
        stats.push(`${props.containment_percent}% contained`);
      }

      if (props.description) {
        stats.push(props.description);
      }

      const statsList = stats.length
        ? `<ul class="tw-mb-1 tw-list-none">
            ${stats.map((stat) => `<li>${stat}</li>`).join('')}
          </ul>`
        : '';

      const getSectionHtml = (title, text) => `
        <div class="tw-mb-1">
          <strong class="tw-block">${title}</strong>
          <span>${text}</span>
        </div>
      `;

      const discovered = props.discovered_at
        ? getSectionHtml('Discovered', props.discovered_at)
        : '';

      const updatedAt = props.updated_at
        ? getSectionHtml(
            sourceLayer === 'perimeters' ? 'Fire Perimeter Updated' : 'Fire Stats Updated',
            props.updated_at,
          )
        : '';

      const fireCause = props.fire_cause
        ? getSectionHtml('Fire Cause', props.fire_cause)
        : '';

      const fireBehavior = props.fire_behavior
        ? getSectionHtml('Fire Behavior', props.fire_behavior)
        : '';

      const fuelSources = props.fire_fuel
        ? getSectionHtml('Fuel Sources', props.fire_fuel)
        : '';

      const html = `
        <div style="min-width: 240px">
          <h6
            class="tw-mb-1 tw-text-base tw-font-bold" style="border-bottom: 1px solid #ccc;"
          >
            ${props.name}
          </h6>
          <div style="line-height: 1.125rem;">
            ${statsList}
            ${discovered}
            ${updatedAt}
            ${fireCause}
            ${fireBehavior}
            ${fuelSources}
          </div>
        </div>
      `;

      return html;
    },

    showPopup(e) {
      const [feature] = e.features;
      const layerId = feature.layer.id;
      const sourceLayer = feature.layer['source-layer'];
      this.popup.mouseEvent = e.originalEvent;

      if (!this.popup.enabled
        || (this.popup.featureId === feature.properties.id && layerId === this.popup.layerId)
        || (this.map.getZoom() < 7 && sourceLayer === 'hotspots')
      ) {
        return;
      }

      const show = () => {
        this.clearPopupTimeout();
        this.popup.control.setHTML(this.htmlPopupHotspots(sourceLayer, feature));

        if (this.popup.featureId !== feature.properties.id) {
          this.popup.control
            .setLngLat(e.lngLat)
            .addTo(this.map);

          if (!this.$device.isMobileOrTablet) {
            this.popup.control.trackPointer();
          }
        }

        this.popup.featureId = feature.properties.id;
        this.popup.layerId = layerId;
      };

      if (!this.$device.isMobileOrTablet) {
        show();
        return;
      }

      window.setTimeout(() => {
        if (e.originalEvent.handledBy) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }
        e.originalEvent.handledBy = this.$options.name;
        show();

        const popup = this.map.getContainer().querySelector(`.${this.popup.className}`);
        const closeHandler = (clickEvent) => {
          this.hidePopupImmediate(clickEvent);
          popup.removeEventListener('click', closeHandler);
        };
        popup.addEventListener('click', closeHandler);
      }, 50);
    },

    hidePopup() {
      if (this.popup.timeoutId) {
        return;
      }

      this.popup.timeoutId = setTimeout(this.hidePopupImmediate, 250);
    },

    hidePopupImmediate() {
      if (this.popup.control.isOpen()) {
        this.popup.control.remove();
      }

      this.popup.featureId = null;
      this.popup.layerId = null;
      this.popup.mouseEvent = null;
      this.popup.timeoutId = null;
    },

    disablePopup() {
      this.popup.enabled = false;
      this.hidePopupImmediate();
    },

    enablePopup() {
      this.popup.enabled = true;
    },

    addEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.on('popup.show', this.disablePopup);
      this.map.on('popup.hide', this.enablePopup);

      if (this.$device.isMobileOrTablet) {
        this.map.on('click', this.hidePopupImmediate);
        this.map.on('movestart', this.hidePopupImmediate);
      }
    },

    removeEvents() {
      if (this.isMiniMap) {
        return;
      }

      this.map.off('popup.show', this.disablePopup);
      this.map.off('popup.hide', this.enablePopup);

      if (this.$device.isMobileOrTablet) {
        this.map.off('click', this.hidePopupImmediate);
        this.map.off('movestart', this.hidePopupImmediate);
      }
    },

    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const source = addSource({ tile }, this);

      if (!source) {
        return;
      }

      const hotspots = this.addHotspots(tile);
      const perimeters = this.addPerimeters(tile, hotspots[hotspots.length - 1].id);
      const fires = this.addFires(tile);
      const labels = this.addLabels(tile);

      const eventLayers = [
        hotspots[0].id,
        perimeters[0].id,
        fires[0].id,
        labels[0].id,
      ];

      let eventsOn = false;
      this.frames.push({
        labels,
        layers: [...hotspots, ...perimeters, ...fires],
        source,
        source_timestamp,
        addEvents: () => {
          if (this.isMiniMap) {
            return;
          }

          if (eventsOn) {
            return;
          }

          if (this.$device.isMobileOrTablet) {
            this.map.on('click', eventLayers, this.showPopup);
          }
          else {
            this.map.on('mousemove', eventLayers, this.showPopup);
            this.map.on('mouseleave', eventLayers, this.hidePopup);
            // keep popup visible while animating but update its content
            // to new feature under pointer
            if (this.popup.mouseEvent) {
              this.map.getCanvas().dispatchEvent(
                new MouseEvent(this.popup.mouseEvent.type, this.popup.mouseEvent),
              );
            }
          }

          eventsOn = true;
        },
        removeEvents: () => {
          if (this.isMiniMap) {
            return;
          }

          this.hidePopup();

          if (this.$device.isMobileOrTablet) {
            this.map.off('click', eventLayers, this.showPopup);
          }
          else {
            this.map.off('mousemove', eventLayers, this.showPopup);
            this.map.off('mouseleave', eventLayers, this.hidePopup);
          }

          eventsOn = false;
        },
      });
    },
  },
};
</script>
