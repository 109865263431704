<script>
import { mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';
import { addFill, addLabels, addSource } from './Utils';

export default {
  name: 'HailOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  data() {
    return {
      frames: [],
      isAllAccess: true,
      popup: {
        className: 'location-hover-popup',
        enabled: true,
        control: null,
        layerId: null,
        featureId: null,
        mouseEvent: null,
        timeoutId: null,
      },
      source: {
        get_forecasts: false,
        has_legend: true,
        has_mask: true,
        has_tile_index: true,
        short_name: OverlayNames.hail,
        tile_count: 24,
        tile_sort: 'asc',
        tile_types: ['contours'],
      },
    };
  },

  computed: {
    ...mapState(useUserStore, {
      units: (state) => state.preferences.units,
    }),
  },

  methods: {
    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();
      this.addHandleIdle();
      await this.createPopup();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      this.addOverlayMask();

      this.tiles.forEach((tile) => {
        try {
          this.addFrame(tile);
        }
        catch (e) {
          // Ignore tiles if they can't be added to the map
        }
      });

      this.addEvents();

      this.show();
    },
    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const source = addSource({ tile }, this);

      if (!source) {
        return;
      }

      const layer = addFill({ fillOpacity: 0, tile }, this, this.currentBaseMap);
      const labels = addLabels({ tile }, this);
      const eventLayers = [
        layer.id,
      ];

      let eventsOn = false;
      this.frames.push({
        labels,
        layer,
        source,
        source_timestamp,
        addEvents: () => {
          if (eventsOn) {
            return;
          }

          if (this.$device.isMobileOrTablet) {
            this.map.on('click', this.hidePopupImmediate);
            this.map.on('movestart', this.hidePopupImmediate);
          }
          if (this.$device.isMobileOrTablet) {
            this.map.on('click', eventLayers, this.showPopup);
          }
          else {
            this.map.on('mousemove', eventLayers, this.showPopup);
            this.map.on('mouseleave', eventLayers, this.hidePopup);
            // keep popup visible while animating but update its content
            // to new feature under pointer
            if (this.popup.mouseEvent) {
              this.map.getCanvas().dispatchEvent(
                new MouseEvent(this.popup.mouseEvent.type, this.popup.mouseEvent),
              );
            }
          }

          eventsOn = true;
        },
        removeEvents: () => {
          this.hidePopup();

          if (this.$device.isMobileOrTablet) {
            this.map.off('click', eventLayers, this.showPopup);
          }
          else {
            this.map.off('mousemove', eventLayers, this.showPopup);
            this.map.off('mouseleave', eventLayers, this.hidePopup);
          }

          eventsOn = false;
        },
      });
    },
    disablePopup() {
      this.popup.enabled = false;
      this.hidePopupImmediate();
    },

    enablePopup() {
      this.popup.enabled = true;
    },

    addEvents() {
      this.map.on('popup.show', this.disablePopup);
      this.map.on('popup.hide', this.enablePopup);
    },

    removeEvents() {
      this.map.off('popup.show', this.disablePopup);
      this.map.off('popup.hide', this.enablePopup);
    },

    async createPopup() {
      this.popup.control = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        closeOnMove: false,
        maxWidth: '300px',
        offset: 10,
        className: this.popup.className,
      });
    },

    clearPopupTimeout() {
      if (!this.popup.timeoutId) {
        return;
      }

      window.clearTimeout(this.popup.timeoutId);
      this.popup.timeoutId = null;
    },
    htmlPopup(sourceLayer, feature) {
      const { properties: props } = feature;

      const multiplier = ['imperial', 'us'].includes(this.units) ? 1 / 25.4 : 1;
      const units = ['imperial', 'us'].includes(this.units) ? '"' : 'mm';
      const value = Math.round(props.DN * multiplier * 100) / 100;

      return `
        <div>
          <div class="tw-text-center">Hail Size: ${value}${units}</div>
        </div>
      `;
    },
    showPopup(e) {
      const [feature] = e.features;
      const layerId = feature.layer.id;
      const sourceLayer = feature.layer['source-layer'];
      this.popup.mouseEvent = e.originalEvent;

      if (!this.popup.enabled || this.map.getZoom() < 4) {
        return;
      }

      const show = () => {
        this.clearPopupTimeout();
        this.popup.control.setHTML(this.htmlPopup(sourceLayer, feature));

        this.popup.control
          .setLngLat(e.lngLat)
          .addTo(this.map);

        this.popup.layerId = layerId;
      };

      if (!this.$device.isMobileOrTablet) {
        show();
        return;
      }

      window.setTimeout(() => {
        if (e.originalEvent.handledBy) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }
        e.originalEvent.handledBy = this.$options.name;
        show();

        const popup = this.map.getContainer().querySelector(`.${this.popup.className}`);
        const closeHandler = (clickEvent) => {
          this.hidePopupImmediate(clickEvent);
          popup.removeEventListener('click', closeHandler);
        };
        popup.addEventListener('click', closeHandler);
      }, 75);
    },

    hidePopup() {
      if (this.popup.timeoutId) {
        return;
      }

      this.popup.timeoutId = setTimeout(this.hidePopupImmediate, 250);
    },

    hidePopupImmediate() {
      if (this.popup.control.isOpen()) {
        this.popup.control.remove();
      }

      this.popup.featureId = null;
      this.popup.layerId = null;
      this.popup.mouseEvent = null;
      this.popup.timeoutId = null;
    },
  },
};
</script>
